import { omit } from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import firebase from 'firebase/compat/app';
import { db } from './database.js';

function partnerRef(partnerId) {
    return db.collection('partners').doc(partnerId);
}

export async function getPartner(partnerId) {
    const partner = await partnerRef(partnerId).get();
    if (partner.exists) return { ...partner.data(), partnerId };
    throw new Error(`Partner with partnerId ${partnerId} not found`);
}

const updatedPartnerDataDebounced = AwesomeDebouncePromise(partner => partnerRef(partner.partnerId).set(omit(partner, ['partnerId'])), 100);

export async function updatePartner(partner) {
    updatedPartnerDataDebounced(partner);
}

function getPrettyYearMonth(date) {
    return date.toISOString().slice(0, 7);
}

export async function addUsage({ processId, customerId, partnerId, advisorId, resourceType, resourceId, source }) {
    const currentDate = new Date;
    const simpleCreationTimestamp = (new Date).toISOString();
    await db.collection('partners').doc(partnerId).collection('usage').doc(resourceType).collection(getPrettyYearMonth(currentDate)).doc(`${processId}|${simpleCreationTimestamp}`).set({
        partnerId,
        createdAt: firebase.firestore.Timestamp.now(),
        customerId,
        processId,
        advisorId,
        resourceId,
        source,
    });
}

export async function countUsage(partnerId, resourceType, month) {
    let localMonth = month;
    if (!localMonth) {
        localMonth = getPrettyYearMonth(new Date);
    }
    const collectionSnapshot = await db.collection('partners').doc(partnerId).collection('usage').doc(resourceType).collection(localMonth).get();
    
    return collectionSnapshot.docs.length ?? 0;
}