import { address, cpr, email, pdfImage, info, text, name, phone, children, kreditDataPension } from './onboardingQuestionTypes.js';
import { account, house, intro, info as groupInfo, invest, pension, salary, comment, taxFolder, budget } from './onboardingQuestionGroupTypes.js';
import { fileTypes } from '../pages/Kunder/Kunde/Files/fileTypes.js';

export function copyQuestionnaire(inputQuestionnaire) {
    return {
        ...inputQuestionnaire,
        questionGroups: inputQuestionnaire.questionGroups.map(questionGroup => ({
            ...questionGroup,
            questions: questionGroup.questions.map(question => ({ ...question })),
        })),
    };
}

export function copyAnswers(inputAnswers) {
    const copyOfInput = {
        ...inputAnswers,
        customerAnswerGroups: inputAnswers.customerAnswerGroups.map(answerGroup => copyAnswerGroup(answerGroup)),
    };

    if(inputAnswers.spouseAnswerGroups) {
        copyOfInput.spouseAnswerGroups = inputAnswers.spouseAnswerGroups.map(answerGroup => copyAnswerGroup(answerGroup));
    }

    if(inputAnswers.sharedAnswerGroups) {
        copyOfInput.sharedAnswerGroups = inputAnswers.sharedAnswerGroups.map(answerGroup => copyAnswerGroup(answerGroup));
    }

    return copyOfInput;
}

function copyAnswerGroup(answerGroup) {
    return {
        ...answerGroup,
        answers: answerGroup.answers.map(answer => {
            if(answer?.answerData?.files?.length >= 0) {
                return {
                    ...answer,
                    answerData: {
                        ...answer.answerData,
                        files: answer.answerData?.files.map(file => ({ ...file })),
                    },
                };
            }
            if(answer?.answerData?.children?.length >= 0) {
                return {
                    ...answer,
                    answerData: {
                        ...answer.answerData,
                        children: answer.answerData?.children?.map(child => ({ ...child })),
                    },
                };
            }
            return { ...answer };
        }),
    };
}

// remember to increment the versionnumber which is set in createOnboardingProcess() in Onboarding.js
// if the default questions gets changed.
export const defaultQuestionnairVersionNumber = '21';

export default [
    {
        id: '10178f22-baff-48d4-a114-039c5d45b5b5',
        title: 'ØkonomiTjek',
        includeSpouse: false,
        spouseName: null,
        questionGroups :  [
            {
                id: '3e69dea8-b8c4-44e7-932c-a7cbb0ba5531',
                title: 'Introbesked',
                guideText: null,
                commentText: null,
                type: intro,
                questions: [
                    {
                        id: '52ae9fbe-a1cb-4f6c-9527-6de595c4366f',
                        type: info,
                        mandatory: false,
                        title: 'Introbesked',
                        hintText: 'For at kunne give den bedste rådgivning er det nødvendigt, at du leverer data til os via dette sikre link.\n\nDer vil undervejs være vejledning til, hvor du finder de forskellige dokumenter.\n\nDet er en god idé, at du inden mødet gør dig overvejelser om, dine ønsker til fremtiden. Det kan f.eks. være overvejelser om: \n\n- hvornår du ønsker at gå på pension?\n- hvordan du tror, at dine faste udgifter være i fremtiden? \n- har du større forudsete udgifter inden for nær fremtid (de næste 5 år)?\n- hvorvidt du ønsker at blive i din nuværende bolig?\n- hvordan ønsker at afdrage på gæld.\n- hvis du har friværdi. Kunne du så overveje at investere i stedet for at afdrage?',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: '36bd1d52-f7d5-4e98-a981-4aae81ebc506',
                title: 'Basisinfo',
                guideText: null,
                commentText: null,
                type: groupInfo,
                questions: [
                    {
                        id: '54b197ce-b80e-49ce-9256-81053c3fe1bf',
                        type: cpr,
                        mandatory: true,
                        title: 'CPR nr.',
                        hintText: 'Indtastvenligst CPR nr inklusiv de fire sidste cifre.',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: 'c77e29f8-d1b8-457e-98d6-9ea7ca81b7bd',
                        type: name,
                        mandatory: true,
                        title: 'Navn',
                        hintText: 'Indtast venligst fulde navn',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '5f5cbb91-b32c-470f-a02c-91100231b51b',
                        type: address,
                        mandatory: true,
                        title: 'Adresse',
                        hintText: 'Indtast venligst din bopælsadresse',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '4d581d8e-46ad-400e-871a-9eedd55b3f51',
                        type: phone,
                        mandatory: true,
                        title: 'Telefonnummer',
                        hintText: 'Indtast venligst dit telefonnummer',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '88c3ea64-bfae-468b-9c75-9b706a6ec109',
                        type: email,
                        mandatory: true,
                        title: 'Email',
                        hintText: 'Indtast venligst dit email',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: 'e1084147-a1ec-45b1-ad5d-0f8bf987a247',
                        type: pdfImage,
                        mandatory: true,
                        title: 'ID (Pas eller Kørekort)',
                        hintText: 'Upload venligst et billed af dit pas eller kørrekort',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.Id,
                        },
                    },
                    {
                        id: '7e2cf64c-ec3a-4fe6-a9a3-705e1954a8b4',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Sygesikringskort',
                        hintText: 'Upload venligst et billed af dit sygesikringskort',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.HealthInsuranceCase,
                        },
                    },
                    {
                        id: '436c19ff-5f84-44bd-a31d-f248d969658d',
                        type: children,
                        mandatory: true,
                        title: 'Hjemmeboende børn',
                        hintText: 'Venligst oplys navn og alder på hjemmeboende børn',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: 'b345e56f-9991-4884-95c9-c5ab5e068a6e',
                title: 'Pensionsdata',
                guideText: '<b>Til at hente pensionsdata samarbejdes der med Uniify.<br /><br />Når der trykkes på knappen vil du blive vidersendt til Uniify som vil hjælpe dig med at sende din pensionsdata til os.',
                commentText: null,
                type: pension,
                questions: [
                    {
                        id: 'c643b270-d635-4c60-a00f-8ffc31095884',
                        type: kreditDataPension,
                        mandatory: true,
                        title: 'Pensionsinfo',
                        hintText: 'Venligst gå gennem Uniify flowet for at sende din pensionsdata til os.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.PensionsInfoFile,
                        },
                    },
                ],
            },
            {
                id: '792610ba-5e34-4185-84f9-e5618348a413',
                title: 'Investeringsdata',
                guideText: '<b>Hvor finder jeg min depotoversigt?</b><br />Du finder depotoversigten i din netbank, eller der hvor du handler aktier.<br /><br />Vær opmærksom på, at der skal fremgå ISIN-koder på oversigten, og at du kan uploade flere PDF\'er, hvis det er nødvendigt.',
                commentText: null,
                type: invest,
                questions: [
                    {
                        id: '825c6b76-d615-4612-a9ad-b577a4c523dc',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Depotoversigt',
                        hintText: 'Upload venligst depotoversigt over dine aktier og investeringsforeninger, vær obs. der skal fremgå ISIN-koder, og at du kan uploade flere pdf’er',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.StockOverview,
                        },
                    },
                ],
            },
            {
                id: '8ad1bd39-a3af-4508-b4be-8462355e287b',
                title: 'Kontoversigt',
                guideText: '<b>Hvor finder jeg min posteringsoversigt?</b><br />Log ind på din netbank for at lave et skærmbillede af dine konti inkl. informationer om saldoen. Hvis du har flere banker, skal du uploade skærmbilleder fra alle banker.',
                commentText: null,
                type: account,
                questions: [
                    {
                        id: 'b0eb1445-492b-4e03-bbf8-5c2f4928a16a',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Kontooversigt',
                        hintText: 'Upload venligst kontooversigt over alle dine kontoer.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.BankAccountOview,
                        },
                    },
                ],
            },
            {
                id: '44751450-b30f-42f6-8846-787514e0ba11',
                title: 'Boligdata',
                guideText: '<b>Hvor finder jeg min terminsopgørelse?</b><br />Oplysninger finder du på din Betalingsservice oversigt, som oftest sendes til E-boks.<br />I nogle tilfælde kan du finde oplysningerne i din netbank ved at klikke på selve betalingen af lånet. <br />Det er vigtigt at følgende oplysninger fremgår af terminskvitteringen:<br />- Periode<br />- Fondskode<br />- Restløbetid<br />- Rente<br /><br />Hvis du har flere boliger f.eks. sommerhus eller erhvervsejendom, skal du uploade terminskvittering for dem alle.',
                commentText: null,
                type: house,
                questions: [
                    {
                        id: '58382281-cf75-424b-95aa-7def6e1f31b7',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Terminskvittering',
                        hintText: 'Upload venligst den seneste terminskvittering. Bemærk, at følgende oplysninger skal fremgå af terminskvitteringen: periode, fondskode, restløbetid og rente',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.BankAccountOview,
                        },
                    },
                    {
                        id: '59db8df3-1fa8-4edc-830e-432e8aae76b7',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Eventuel fritidshuse/erhvervsejendomme terminskvitteringer',
                        hintText: 'Upload venligst den seneste terminskvittering. Bemærk, at følgende oplysninger skal fremgå af terminskvitteringen: periode, fondskode, restløbetid og rente',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.BankAccountOview,
                        },
                    },
                ],
            },
            {
                id: '8ae17232-37ad-4c09-98d0-d8b128ade55d',
                title: 'Løndata',
                guideText: '<b>Hvor finder jeg mine lønsedler?</b><br />Normalt finder du dine lønsedler i E-boks.<br />Hvis du får lønsedler i printet form, skal tage et foto med din mobiltelefon eller scanne dem.<br /> Herefter skal du gemme dem på din computer som PDF-filer. ',
                commentText: null,
                type: salary,
                questions: [
                    {
                        id: '7bcbe84f-619c-475a-8cfc-499e22190f06',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Tre seneste lønsedler - første lønseddel, efterløn etc.',
                        hintText: 'Upload venligst de tre seneste lønsedler, efterløn etc.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.SalaryData,
                        },
                    },
                    {
                        id: '8e871a08-37ec-4287-b0f9-f517bce5474e',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Anden lønseddel, efterløn etc.',
                        hintText: 'Upload venligst de tre seneste lønsedler, efterløn etc.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.SalaryData,
                        },
                    },
                    {
                        id: '0a6c9039-6688-4540-84d6-105b1fb70294',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Tredje lønseddel, efterløn etc.',
                        hintText: 'Upload venligst de tre seneste lønsedler, efterløn etc.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.SalaryData,
                        },
                    },
                ],
            },
            {
                id: 'f8866ae4-45ba-4f39-8982-0cf931c05dd8',
                title: 'Budget',
                guideText: '<b>Hvordan laver jeg et budget?</b><br />På Finanstilsynets hjemmeside raadtilpenge.dk finder du en digital budgetskabelon, der gør det nemt for dig at lægge et budget.<br />1. Gå ind på hjemmesiden <a href="https://www.raadtilpenge.dk/penge-beregner/budgetskema/">raadtilpenge.dk/penge-beregner/budgetskema/</a><br />2. Vælg om du ønkser at lave budget for én person eller som par.<br />3. Udfyld alle relevante felter.<br />4. Tryk på \'Download Excel\' for at downloade dokumentet.<br />5. Upload dokumentet til her.',
                commentText: null,
                type: budget,
                questions: [
                    {
                        id: '1e9b3dae-db8f-4e9b-8a7f-d590598aa227',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Budgetfil',
                        hintText: 'Upload venligst dit budget',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.Budget,
                        },
                    },
                ],
            },
            {
                id: 'ae41d9ef-97a7-4109-a37a-57613db3daf6',
                title: 'Skattemappe',
                guideText: '<b>Skattemappe</b><br />Din skattemappe finder du på skat.dk. Sådan giver du rådgiveren adgang til din skatte mappe.<br />1. Log ind på skat.dk<br />2. Tryk på ‘Profil’ i højre hjørne<br />3. Tryk på fanebladet ‘Giv adgang til rådgivere eller andre’<br />4. Indtast følgende CVR-nummer: xxxx i felterne:<br />- ‘Jeg giver adgang til’ <br />- ‘Bekræft adgang’<br />5. Sæt hak i ‘Adgang til alle oplysninger’ nederst på siden.<br />6. Afslut ved at trykke ‘Godkend’',
                commentText: null,
                type: taxFolder,
                questions: [
                    {
                        id: 'f9b1526b-1675-4b16-8d5f-239b54d1fe27',
                        type: info,
                        mandatory: false,
                        title: 'Skattemappe',
                        hintText: '<img src=\'/assets/img/default/skattemappe.png\' />',
                        shared: false,
                        alwaysShared: false,
                        selected: true,
                    },
                ],
            },
            {
                id: '2f8b52ae-b8ec-4676-8bb2-dc99faf5a078',
                title: 'Bemærkninger til rådgiver',
                guideText: null,
                commentText: null,
                type: comment,
                questions: [
                    {
                        id: '3551f4cf-b080-47e1-9de0-bf02336ec21c',
                        type: text,
                        mandatory: false,
                        title: 'Bemærkninger',
                        hintText: null,
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
        ],
    },
    {
        id: 'bf50dc3b-5249-4c3c-ab78-ba9bd015e306',
        title: 'InvestTjek',
        includeSpouse: false,
        spouseName: null,
        questionGroups :  [
            {
                id: 'b970979e-0249-48b5-8d59-da30c07d00ed',
                title: 'Introbesked',
                guideText: null,
                commentText: null,
                type: intro,
                questions: [
                    {
                        id: '948be1d5-3708-40a4-9f4f-9cb07d2d45c8',
                        type: info,
                        mandatory: false,
                        title: 'Introbesked',
                        hintText: 'For at kunne rådgive dig bedst, er det nødvendigt at vi kan indhendte din data. Der vil undervejs være vejledning til, hvor du finder de forskellige dokumenter. Udfyld dette sikre link hurtigst muligt. Det udløber om 14 dage.',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: 'd0d0cb37-eb87-466d-9781-a3071c0bd8bb',
                title: 'Basisinfo',
                guideText: null,
                commentText: null,
                type: info,
                questions: [
                    {
                        id: '730bc2b3-05e7-4f62-9204-ad09c9cf4fcf',
                        type: cpr,
                        mandatory: true,
                        title: 'CPR nr.',
                        hintText: 'Indtastvenligst CPR nr inklusiv de fire sidste cifre.',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: 'abb4e90e-1d90-43a1-b61a-856b7dec8df7',
                        type: name,
                        mandatory: true,
                        title: 'Navn',
                        hintText: 'Indtast venligst fulde navn',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '4075cdae-4ca1-4152-ab99-362556b81050',
                        type: address,
                        mandatory: true,
                        title: 'Adresse',
                        hintText: 'Indtast venligst din bopælsadresse',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '4f042bfd-42c6-43b4-a5f4-c6d205a0d480',
                        type: phone,
                        mandatory: true,
                        title: 'Telefonnummer',
                        hintText: 'Indtast venligst dit telefonnummer',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '09bd1f06-e344-4c3f-b617-d58403ff5adc',
                        type: email,
                        mandatory: true,
                        title: 'Email',
                        hintText: 'Indtast venligst dit email',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '50a61ad9-b6ed-44df-9667-2973d7a0f467',
                        type: pdfImage,
                        mandatory: true,
                        title: 'ID',
                        hintText: 'Basisinfo',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.Id,
                        },
                    },
                    {
                        id: '436c19ff-5f84-44bd-a31d-f248d969658d',
                        type: children,
                        mandatory: true,
                        title: 'Hjemmeboende børn',
                        hintText: 'Venligst oplys navn og alder på hjemmeboende børn',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: 'f8d110b2-e332-4949-854c-82b7e4330f00',
                title: 'Pensionsdata',
                guideText: '<b>Til at hente pensionsdata samarbejdes der med Uniify.<br /><br />Når der trykkes på knappen vil du blive vidersendt til Uniify som vil hjælpe dig med at sende din pensionsdata til os.',
                commentText: null,
                type: pension,
                questions: [
                    {
                        id: 'cc9b167c-a16b-472d-b226-6574b189537c',
                        type: kreditDataPension,
                        mandatory: true,
                        title: 'Pensionsinfo',
                        hintText: 'Venligst gå gennem Uniify flowet for at sende din pensionsdata til os.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.PensionsInfoFile,
                        },
                    },
                ],
            },
            {
                id: 'd1f2fcac-0b67-476f-86bf-75f03054f9c4',
                title: 'Investeringsdata',
                guideText: null,
                commentText: null,
                type: invest,
                questions: [
                    {
                        id: '6e6f7f0d-23e2-45ef-89c7-218b475c4f61',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Depotoversigt',
                        hintText: 'Upload venligst depotoversigt over dine aktier/investeringsforeninger, vær obs. der skal fremgå ISIN koder og at du kan uploade flere PDF\'er',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.StockOverview,
                        },
                    },
                ],
            },
            {
                id: 'caa72811-72b8-4a4a-aab8-53e4f3836702',
                title: 'Kontoversigt',
                guideText: null,
                commentText: null,
                type: account,
                questions: [
                    {
                        id: 'ce369eee-0e21-45e5-8433-2c5cb20a7a9c',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Kontooversigt',
                        hintText: 'Upload venligst kontooversigt over alle dine kontoer.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.BankAccountOview,
                        },
                    },
                ],
            },
            {
                id: '44751450-b30f-42f6-8846-787514e0ba11',
                title: 'Boligdata',
                guideText: null,
                commentText: null,
                type: house,
                questions: [
                    {
                        id: '58382281-cf75-424b-95aa-7def6e1f31b7',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Boligdata',
                        hintText: 'Upload venligst den seneste terminskvittering',
                        shared: false,
                        selected: false,
                        data: {
                            pdfType: fileTypes.HouseData,
                        },
                    },
                ],
            },
            {
                id: '8ae17232-37ad-4c09-98d0-d8b128ade55d',
                title: 'Løndata',
                guideText: null,
                commentText: null,
                type: salary,
                questions: [
                    {
                        id: '7bcbe84f-619c-475a-8cfc-499e22190f06',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Tre seneste lønsedler, efterløn etc.',
                        hintText: 'Upload venligst de tre seneste lønsedler, efterløn etc.',
                        shared: false,
                        selected: false,
                        data: {
                            pdfType: fileTypes.SalaryData,
                        },
                    },
                ],
            },
            {
                id: 'c4d3d12c-6ee1-4b19-90b6-d3d00c59d293',
                title: 'Bemærkninger til rådgiver',
                guideText: null,
                commentText: null,
                type: comment,
                questions: [
                    {
                        id: '085c78e1-d009-4573-844b-88cce4a330af',
                        type: text,
                        mandatory: false,
                        title: 'Bemærkninger',
                        hintText: null,
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
        ],
    },
    {
        id: '986552e5-5a42-41ba-b975-ee821441d54a',
        title: 'PensionsTjek',
        includeSpouse: false,
        spouseName: null,
        questionGroups :  [
            {
                id: '48dda37d-177d-447f-b1cd-1855c78c525d',
                title: 'Introbesked',
                guideText: null,
                commentText: null,
                type: intro,
                questions: [
                    {
                        id: '7a700da8-0ce9-4b5a-b61a-8c2978374e12',
                        type: info,
                        mandatory: false,
                        title: 'Introbesked',
                        hintText: 'For at kunne rådgive dig bedst, er det nødvendigt at vi kan indhendte din data. Der vil undervejs være vejledning til, hvor du finder de forskellige dokumenter. Udfyld dette sikre link hurtigst muligt. Det udløber om 14 dage.',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: 'ece343ae-5de5-43b4-ad83-0bce17bb243a',
                title: 'Basisinfo',
                guideText: null,
                commentText: null,
                type: info,
                questions: [
                    {
                        id: '0a55e2ce-9919-4c75-a6ab-83d577e18bdb',
                        type: cpr,
                        mandatory: true,
                        title: 'CPR nr.',
                        hintText: 'Indtastvenligst CPR nr inklusiv de fire sidste cifre.',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '1c257f3b-d322-4a50-b3f2-92392a2c8b03',
                        type: name,
                        mandatory: true,
                        title: 'Navn',
                        hintText: 'Indtast venligst fulde navn',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '10d5ec62-c948-4c3e-85bf-7b94d51b5386',
                        type: address,
                        mandatory: true,
                        title: 'Adresse',
                        hintText: 'Indtast venligst din bopælsadresse',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '3ac06d68-9c01-4aa4-8b11-42b38f1e4d4a',
                        type: phone,
                        mandatory: true,
                        title: 'Telefonnummer',
                        hintText: 'Indtast venligst dit telefonnummer',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: '2bc01c73-7c70-4e06-8e1f-6a85cbf6d59a',
                        type: email,
                        mandatory: true,
                        title: 'Email',
                        hintText: 'Indtast venligst dit email',
                        shared: false,
                        selected: true,
                    },
                    {
                        id: 'baf920f8-7814-4c41-ab96-0cd1d9be15b7',
                        type: pdfImage,
                        mandatory: true,
                        title: 'ID',
                        hintText: 'Upload venligst et billed af dit pas eller kørrekort',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.Id,
                        },
                    },
                    {
                        id: '7b7993b3-039c-443e-a593-d5a964472178',
                        type: children,
                        mandatory: true,
                        title: 'Hjemmeboende børn',
                        hintText: 'Venligst oplys navn og alder på hjemmeboende børn',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: '3444e0e3-b3c7-454d-a271-e21bbe8d2ab9',
                title: 'Pensionsdata',
                guideText: '<b>Til at hente pensionsdata samarbejdes der med Uniify.<br /><br />Når der trykkes på knappen vil du blive vidersendt til Uniify som vil hjælpe dig med at sende din pensionsdata til os.',
                commentText: null,
                type: pension,
                questions: [
                    {
                        id: '35c42cdf-a050-4895-80ac-ef855c213492',
                        type: kreditDataPension,
                        mandatory: true,
                        title: 'Pensionsinfo',
                        hintText: 'Venligst gå gennem Uniify flowet for at sende din pensionsdata til os.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.PensionsInfoFile,
                        },
                    },
                ],
            },
            {
                id: '792610ba-5e34-4185-84f9-e5618348a413',
                title: 'Investeringsdata',
                guideText: null,
                commentText: null,
                type: invest,
                questions: [
                    {
                        id: '825c6b76-d615-4612-a9ad-b577a4c523dc',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Depotoversigt',
                        hintText: 'Upload venligst depotoversigt over dine aktier/investeringsforeninger, vær obs. der skal fremgå ISIN koder og at du kan uploade flere PDF\'er',
                        shared: false,
                        selected: false,
                        data: {
                            pdfType: fileTypes.StockOverview,
                        },
                    },
                ],
            },
            {
                id: '8ad1bd39-a3af-4508-b4be-8462355e287b',
                title: 'Kontoversigt',
                guideText: null,
                commentText: null,
                type: account,
                questions: [
                    {
                        id: 'b0eb1445-492b-4e03-bbf8-5c2f4928a16a',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Kontooversigt',
                        hintText: 'Upload venligst kontooversigt over alle dine kontoer.',
                        shared: false,
                        selected: false,
                        data: {
                            pdfType: fileTypes.BankAccountOview,
                        },
                    },
                ],
            },
            {
                id: '44751450-b30f-42f6-8846-787514e0ba11',
                title: 'Boligdata',
                guideText: null,
                commentText: null,
                type: house,
                questions: [
                    {
                        id: '58382281-cf75-424b-95aa-7def6e1f31b7',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Boligdata',
                        hintText: 'Upload venligst den seneste terminskvittering',
                        shared: false,
                        selected: false,
                        data: {
                            pdfType: fileTypes.HouseData,
                        },
                    },
                ],
            },
            {
                id: '8dc4873b-0a2e-4a12-8624-fb05d00aa432',
                title: 'Løndata',
                guideText: null,
                commentText: null,
                type: salary,
                questions: [
                    {
                        id: 'a71f2d6f-b7fb-4add-b300-5c0b03410345',
                        type: pdfImage,
                        mandatory: true,
                        title: 'Tre seneste lønsedler, efterløn etc.',
                        hintText: 'Upload venligst de tre seneste lønsedler, efterløn etc.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.SalaryData,
                        },
                    },
                ],
            },
            {
                id: 'd53e388f-d1ba-4e50-9732-fc140ca2d94d',
                title: 'Bemærkninger til rådgiver',
                guideText: null,
                commentText: null,
                type: comment,
                questions: [
                    {
                        id: 'e5d83004-f75f-48fd-9410-96b8f6e57610',
                        type: text,
                        mandatory: false,
                        title: 'Bemærkninger',
                        hintText: null,
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
        ],
    },
    {
        id: '18bcec63-4ab9-4134-bb4b-790e53a633c0',
        title: 'Nedsparing',
        includeSpouse: false,
        spouseName: null,
        questionGroups: [
            {
                id: '254ea0b2-9d2e-4b57-94a8-8b054c80f841',
                title: 'Introbesked',
                guideText: null,
                commentText: null,
                type: intro,
                questions: [
                    {
                        id: '8be15998-7b05-454a-a078-4a5310e3c55a',
                        type: info,
                        mandatory: false,
                        title: 'Introbesked',
                        hintText: 'For at kunne rådgive dig bedst, er det nødvendigt at vi kan indhendte din data. Der vil undervejs være vejledning til, hvor du finder de forskellige dokumenter. Udfyld dette sikre link hurtigst muligt. Det udløber om 14 dage.',
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
            {
                id: '27f5c1bf-7c77-4cfb-bfd5-3d6bf3135dd7',
                title: 'Pensionsdata',
                guideText: '<b>Til at hente pensionsdata samarbejdes der med Uniify.<br /><br />Når der trykkes på knappen vil du blive vidersendt til Uniify som vil hjælpe dig med at sende din pensionsdata til os.',
                commentText: null,
                type: pension,
                questions: [
                    {
                        id: '8a7832f2-80f5-4394-ab0d-c71f0e48c095',
                        type: kreditDataPension,
                        mandatory: true,
                        title: 'Pensionsinfo',
                        hintText: 'Venligst gå gennem Uniify flowet for at sende din pensionsdata til os.',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.PensionsInfoFile,
                        },
                    },
                    {
                        id: 'eef202ed-921e-476e-b4c4-6cf1492b5007',
                        type: pdfImage,
                        mandatory: false,
                        title: 'Oversigt over pensionspuljer',
                        hintText: 'Upload venligst en (eller flere) pdf\' som viser en oversigt over dine pensionspuljer',
                        shared: false,
                        selected: true,
                        data: {
                            pdfType: fileTypes.PensionsDepots,
                        },
                    },
                ],
            },
            {
                id: '3693d11a-228a-45b5-bdd0-88efa12394f4',
                title: 'Bemærkninger til rådgiver',
                guideText: null,
                commentText: null,
                type: comment,
                questions: [
                    {
                        id: '8596587e-8cfb-4e2d-8573-8d561ac2cc39',
                        type: text,
                        mandatory: false,
                        title: 'Bemærkninger',
                        hintText: null,
                        shared: true,
                        alwaysShared: true,
                        selected: true,
                    },
                ],
            },
        ],
    },
];
